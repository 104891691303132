

import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Row, Col, Card, Button } from "react-bootstrap";
import AxiosInstance from "../../../api/AxiosInstance";
import { message } from "antd";
import { FaArrowRight } from "react-icons/fa6";

import "./BookingForm.css";
import ServicesDropdown from "./ServicesDropdown";

const BookingForm = () => {
  const [isLink, setIsLink] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [service, setService] = useState([]);
  const [messageApi, contextHolder] = message.useMessage();

  const [allServiceTotal, setAllServiceTotal] = useState("");
  const [propertyServiceTotal, setAllPropertyServiceTotal] = useState(null);

  const successMessage = () => {
    messageApi.open({
      type: "success",
      content: "Booking confirmed successfully!",
      duration: 2,
    });
  };

  const errorMessage = () => {
    messageApi.open({
      type: "error",
      content: "Failed to confirm booking.",
      duration: 2,
    });
  };

  useEffect(() => {
    fetchService();
  }, []);

  const fetchService = async () => {
    console.log("Fetching services...");
    try {
      const response = await AxiosInstance.get("/service/get-service");
      if (response.status === 200) {
        setService(response.data.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .matches(/^[A-Za-z\s]+$/, "Name can only contain letters and spaces")
      .test(
        "no-leading-space",
        "Name cannot start with a space",
        (value) =>
          typeof value === "string" && !/^\s/.test(value) && value.length > 0
      )
      .max(20, "Name cannot be longer than 20 characters")
      .required("Name is required"),
    email: Yup.string()
      .email("Invalid email format")
      .test(
        "no-leading-space",
        "email cannot start with a space",
        (value) =>
          typeof value === "string" && !/^\s/.test(value) && value.length > 0
      )
      .required("Email is required"),
    phone: Yup.string()
      .matches(
        /^[1-9]\d{9}$/,
        "Must be a valid phone number without leading zeros"
      )
      .required("Phone number is required"),
    services: Yup.array()
      .of(Yup.string().required("Service ID must be a string"))
      .min(1, "Please select at least one service")
      .required("Services are required"),
    serviceInputs: Yup.object().shape({}),

    link: Yup.string().nullable(),
    doorNo: Yup.string()
      .max(10, "doorNo cannot be longer than 10 characters")
      .nullable(),
    street: Yup.string()
      .max(50, "street cannot be longer than 50 characters")
      .nullable(),
    area: Yup.string()
      .matches(/^[A-Za-z\s]+$/, "Area can only contain letters and spaces")
      .max(50, "area cannot be longer than 50 characters")
      .nullable(),
    city: Yup.string()
      .matches(/^[A-Za-z\s]+$/, "city can only contain letters and spaces")
      .max(50, "city cannot be longer than 50 characters")
      .nullable(),
    pincode: Yup.string()
      .matches(
        /^[1-9]\d{5}$/,
        "Pincode must be exactly 6 digits and cannot start with 0"
      )
      .nullable(),
    landmark: Yup.string()
      .max(50, "landmark cannot be longer than 50 characters")
      .nullable(),
  });

  const handleLinkToggle = (setFieldValue) => {
    setIsLink((prev) => {
      const newLinkState = !prev;
      if (newLinkState) {
        setFieldValue("doorNo", "");
        setFieldValue("street", "");
        setFieldValue("area", "");
        setFieldValue("city", "");
        setFieldValue("pincode", "");
        setFieldValue("landmark", "");
      } else {
        setFieldValue("link", "");
      }
      return newLinkState;
    });
  };

  const handleSubmit = async (values, { resetForm }) => {
    const selectedServices = values.services;
    if (isLink && !values.link) {
      alert("Link is required when using the link option.");
      return;
    }

    if (!isLink) {
      const requiredFields = [
        "doorNo",
        "street",
        "area",
        "city",
        "pincode",
        "landmark",
      ];
      const missingFields = requiredFields.filter((field) => !values[field]);
      if (missingFields.length > 0) {
        alert(`Please fill in all address fields: ${missingFields.join(", ")}`);
        return;
      }
    }

    const data = {
      name: values.name,
      email: values.email,
      phone: values.phone,
      services: selectedServices,
      serviceDetails: values.serviceInputs,
      ServiceTotal: allServiceTotal,
      link: isLink ? values.link : null,
      address: isLink
        ? null
        : {
            doorNo: values.doorNo,
            street: values.street,
            area: values.area,
            city: values.city,
            pincode: values.pincode,
            landmark: values.landmark,
          },
    };

    try {
      const response = await AxiosInstance.post("/booking/add-booking", data);
      successMessage();
      setFormSubmitted(true);
      resetForm();
    } catch (error) {
      console.error("Error submitting form:", error);
      errorMessage();
    } finally {
      setTimeout(setFormSubmitted(false), 3000);
    }
  };

  return (
    <section className="form-section-container">
      {contextHolder}
      <div className="container">
        <Row className="my-5">
          <Col md={12}>
            <div>
              <h3 className="mb-5">Booking Form</h3>
              <Formik
                initialValues={{
                  name: "",
                  email: "",
                  phone: "",
                  services: [],
                  serviceInputs: {},
                  ServiceTotal: 0,
                  link: "",
                  doorNo: "",
                  street: "",
                  area: "",
                  city: "",
                  pincode: "",
                  landmark: "",
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ values, isSubmitting, setErrors, setFieldValue }) => (
                  <Form>
                    <Row className="mb-3">
                      <Col md={6} className="form-col-group">
                        <label htmlFor="name">Name</label>
                        <Field
                          type="text"
                          name="name"
                          placeholder="Enter a Name"
                          className="form-control"
                        />
                        <ErrorMessage
                          name="name"
                          component="div"
                          className="text-danger"
                        />
                      </Col>
                      <Col md={6} className="form-col-group">
                        <label htmlFor="email">Email</label>
                        <Field
                          type="email"
                          name="email"
                          placeholder="Enter a Email"
                          className="form-control"
                        />
                        <ErrorMessage
                          name="email"
                          component="div"
                          className="text-danger"
                        />
                      </Col>
                    </Row>

                    <Row className="mb-3">
                      <Col md={6} className="form-col-group">
                        <label htmlFor="phone">Phone</label>
                        <Field
                          type="text"
                          name="phone"
                          placeholder="Enter a phone Number"
                          className="form-control"
                        />
                        <ErrorMessage
                          name="phone"
                          component="div"
                          className="text-danger"
                        />
                      </Col>
                      <Col md={6} className="form-col-group">
                        <ServicesDropdown
                          services={service}
                          values={values}
                          setFieldValue={setFieldValue} 
                        />
                      </Col>
                    </Row>

                    {values.services.length > 0 &&
                      values.services.map((service, index) => (
                        <Row key={index} className="mb-3">
                          <Col md={service === "Property Maintenance" ? 6 : 12}>
                            <label htmlFor={`serviceInputs.${service}`}>
                              {service}
                            </label>

                            {service === "Property Maintenance" ? (
                              <>
                                <div>
                                  <Field
                                    type="number"
                                    name={`serviceInputs.${service}.sqt`}
                                    max={10000000}
                                    min={1}
                                    onChange={(e) => {
                                      let inputValue = e.target.value;

                                      if (/^\s/.test(inputValue)) {
                                        inputValue = inputValue.trimStart();
                                      }

                                      const numericValue = Number(inputValue);

                                      if (numericValue < 1) {
                                        inputValue = "";
                                      } else if (numericValue > 10000000) {
                                        inputValue = 10000000;
                                      }

                                      setFieldValue(
                                        `serviceInputs.${service}.sqt`,
                                        inputValue
                                      );
                                    }}
                                    placeholder="Enter square footage"
                                    className="form-control mt-2"
                                  />
                                </div>
                              </>
                            ) : (
                              <Field
                                as="textarea"
                                name={`serviceInputs.${service}.description`}
                                placeholder={`Enter details for ${service}`}
                                onChange={(e) => {
                                  const inputValue = e.target.value;

                                  if (/^\s/.test(inputValue)) {
                                    e.target.value = inputValue.trimStart();
                                  }

                                  setFieldValue(
                                    `serviceInputs.${service}.description`,
                                    e.target.value
                                  );
                                }}
                                className="form-control"
                                maxLength={255}
                              />
                            )}

                            {/* Error message */}
                            <ErrorMessage
                              name={`serviceInputs.${service}.description`}
                              component="div"
                              className="text-danger"
                            />
                          </Col>

                          <Col md={service === "Property Maintenance" ? 6 : 12}>
                            {service === "Property Maintenance" && (
                              <Row className="mb-3">
                                <Col md={12}>
                                  <div>
                                    <label>
                                      Total Price for Property Maintenance
                                    </label>
                                    <div className="form-control mt-2 p-2">
                                      {values.services.includes(
                                        "Property Maintenance"
                                      ) && (
                                        <div>
                                          {/* Calculate total price for Property Maintenance */}
                                          {values.services.map((service) => {
                                            if (
                                              service === "Property Maintenance"
                                            ) {
                                              const price =
                                                parseFloat(
                                                  values.serviceInputs[service]
                                                    ?.price
                                                ) || 0;
                                              const sqt =
                                                parseInt(
                                                  values.serviceInputs[service]
                                                    ?.sqt,
                                                  10
                                                ) || 1;
                                              const total = price * sqt;
                                              setAllPropertyServiceTotal(total);

                                              return (
                                                <div key={service}>
                                                  {total}

                                                  <Field
                                                    type="hidden"
                                                    name="serviceInputs.Property Maintenance.total"
                                                    value={total}
                                                  />
                                                </div>
                                              );
                                            }
                                            return null;
                                          })}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            )}
                          </Col>
                        </Row>
                      ))}

                    {/* Calculate Total Price for All Selected Services */}
                    {values.services.length > 0 && (
                      <Row className="mb-3">
                        <Col md={12}>
                          <div>
                            <label>Total Price for All Services</label>
                            <div className="form-control mt-2 p-2">
                              {values.services.reduce((total, service) => {
                                const price =
                                  parseFloat(
                                    values.serviceInputs[service]?.price
                                  ) || 0;
                                const sqt =
                                  parseInt(
                                    values.serviceInputs[service]?.sqt,
                                    10
                                  ) || 1;
                                console.log(total + price * sqt);
                                setAllServiceTotal(total + price * sqt);
                                return total + price * sqt;
                              }, 0)}
                            </div>
                          </div>
                        </Col>
                      </Row>
                    )}

                    <Row className="mb-3">
                      <Col md={6} className="form-col-group">
                        <div className="form-check">
                          <input
                            type="checkbox"
                            id="isLink"
                            checked={isLink}
                            onChange={() => handleLinkToggle(setFieldValue)}
                            className="form-check-input"
                          />
                          <label className="form-check-label" htmlFor="isLink">
                            Use a Link instead of Address
                          </label>
                        </div>
                      </Col>
                    </Row>

                    {isLink ? (
                      <Row className="mb-3">
                        <Col md={12} className="form-col-group">
                          <label htmlFor="link">Link - (Location Link)</label>
                          <Field
                            type="text"
                            name="link"
                            placeholder="Enter a Location Link"
                            className="form-control"
                            onChange={(e) => {
                              const inputValue = e.target.value;

                              if (/^\s/.test(inputValue)) {
                                e.target.value = inputValue.trimStart();
                              }

                              setFieldValue("link", e.target.value);
                            }}
                          />
                          <ErrorMessage
                            name="link"
                            component="div"
                            className="text-danger"
                          />
                        </Col>
                      </Row>
                    ) : (
                      <>
                        <Row className="mb-3">
                          <Col md={6} className="form-col-group">
                            <label htmlFor="doorNo">Door No</label>
                            <Field
                              type="text"
                              name="doorNo"
                              placeholder="Enter a Door Number"
                              className="form-control"
                              onChange={(e) => {
                                const inputValue = e.target.value;

                                if (/^\s/.test(inputValue)) {
                                  e.target.value = inputValue.trimStart();
                                }

                                setFieldValue("doorNo", e.target.value);
                              }}
                            />
                            <ErrorMessage
                              name="doorNo"
                              component="div"
                              className="text-danger"
                            />
                          </Col>

                          <Col md={6} className="form-col-group">
                            <label htmlFor="street">Street</label>
                            <Field
                              type="text"
                              name="street"
                              placeholder="Enter a Street"
                              className="form-control"
                              onChange={(e) => {
                                const inputValue = e.target.value;

                                if (/^\s/.test(inputValue)) {
                                  e.target.value = inputValue.trimStart();
                                }

                                setFieldValue("street", e.target.value);
                              }}
                            />
                            <ErrorMessage
                              name="street"
                              component="div"
                              className="text-danger"
                            />
                          </Col>
                        </Row>

                        <Row className="mb-3">
                          <Col md={6} className="form-col-group">
                            <label htmlFor="area">Area</label>
                            <Field
                              type="text"
                              name="area"
                              placeholder="Enter a Area"
                              className="form-control"
                              onChange={(e) => {
                                const inputValue = e.target.value;

                                if (/^\s/.test(inputValue)) {
                                  e.target.value = inputValue.trimStart();
                                }

                                setFieldValue("area", e.target.value);
                              }}
                            />
                            <ErrorMessage
                              name="area"
                              component="div"
                              className="text-danger"
                            />
                          </Col>

                          <Col md={6} className="form-col-group">
                            <label htmlFor="city">City</label>
                            <Field
                              type="text"
                              name="city"
                              placeholder="Enter a City"
                              className="form-control"
                              onChange={(e) => {
                                const inputValue = e.target.value;

                                if (/^\s/.test(inputValue)) {
                                  e.target.value = inputValue.trimStart();
                                }

                                setFieldValue("city", e.target.value);
                              }}
                            />
                            <ErrorMessage
                              name="city"
                              component="div"
                              className="text-danger"
                            />
                          </Col>
                        </Row>

                        <Row className="mb-3">
                          <Col md={6} className="form-col-group">
                            <label htmlFor="pincode">Pincode</label>
                            <Field
                              type="text"
                              name="pincode"
                              placeholder="Enter a Pincode"
                              className="form-control"
                              onChange={(e) => {
                                const inputValue = e.target.value;

                                if (/^\s/.test(inputValue)) {
                                  e.target.value = inputValue.trimStart();
                                }

                                setFieldValue("pincode", e.target.value);
                              }}
                            />
                            <ErrorMessage
                              name="pincode"
                              component="div"
                              className="text-danger"
                            />
                          </Col>

                          <Col md={6} className="form-col-group">
                            <label htmlFor="landmark">Landmark</label>
                            <Field
                              type="text"
                              name="landmark"
                              placeholder="Enter a landmark"
                              className="form-control"
                              onChange={(e) => {
                                const inputValue = e.target.value;

                                if (/^\s/.test(inputValue)) {
                                  e.target.value = inputValue.trimStart();
                                }

                                setFieldValue("landmark", e.target.value);
                              }}
                            />
                            <ErrorMessage
                              name="landmark"
                              component="div"
                              className="text-danger"
                            />
                          </Col>
                        </Row>
                      </>
                    )}

                    <div className="form-footer">
                      <Button
                        variant="primary"
                        type="submit"
                        className="form-submit-btn"
                        disabled={isSubmitting || formSubmitted}
                      >
                        Submit <FaArrowRight />
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default BookingForm;