import React, { useEffect, useState, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import "./Navbar.css";
import { MdOutlineLightMode } from "react-icons/md";
import { MdOutlineDarkMode } from "react-icons/md";
import GtLogo from "../../../../assets/newLogo.jpeg";
import ThemeContext from "../../../../context/ThemeContext";

import { BiPhoneCall } from "react-icons/bi";

const Navbar = () => {
  const [isNavbarFixed, setIsNavbarFixed] = useState(false);
  const [activeSection, setActiveSection] = useState("#");
  const location = useLocation();
  const { theme, toggleTheme, GtInfo } = useContext(ThemeContext);

  const handleLinkClick = () => {
    window.scrollTo({ top: "0 !important ", behavior: "smooth" });
  };

  useEffect(() => {
    const navOffsetTop =
      document.querySelector(".header_area").offsetHeight + 50;

    const handleScroll = () => {
      const scroll = window.scrollY;
      if (scroll >= navOffsetTop) {
        setIsNavbarFixed(true);
      } else {
        setIsNavbarFixed(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (location.hash) {
      setActiveSection(location.hash);
    } else {
      setActiveSection("/");
      handleLinkClick();
    }
  }, [location.hash]);

  const isActive = (path) => (activeSection === path ? "active" : "");

  return (
    <header
      className={`header_area ${isNavbarFixed ? "navbar_fixed" : ""} ${theme}`}
    >
      <div className="main_menu" id="mainNav" style={{ zIndex: 999 }}>
        <nav className="navbar navbar-expand-lg">
          <div className="container-fluid">
            <Link className="navbar-brand logo_h" to="/">
              <img
                src={GtLogo}
                alt="global tamizhan logo"
                width="140px"
                height="140px"
              />
              <img
                src={GtLogo}
                alt="global tamizhan logo"
                width="120px"
                height="120px"
              />
            </Link>
            <button
              className="navbar-toggler me-3"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="icon-bar" />
              <span className="icon-bar" />
              <span className="icon-bar" />
            </button>

            <div
              className="collapse navbar-collapse offset"
              id="navbarSupportedContent"
            >
              <ul className="nav navbar-nav menu_nav ml-auto">
                <li className={`nav-item ${isActive("/")}`}>
                  <Link className="nav-link" to="/">
                    Home
                  </Link>
                </li>
                <li className={`nav-item ${isActive("#about-us")}`}>
                  <Link className="nav-link" to="#about-us">
                    About
                  </Link>
                </li>
                <li className={`nav-item ${isActive("#service")}`}>
                  <Link className="nav-link" to="#service">
                    Service
                  </Link>
                </li>
                <li className={`nav-item ${isActive("#booking-form")}`}>
                  <Link className="nav-link" to="#booking-form">
                    Book Now
                  </Link>
                </li>
              </ul>
              <button className="animate-button ms-4 nav-phone-btn">
                <div className="d-flex align-items-center">
                  <BiPhoneCall className="me-1 fs-5" />
                  <a
                    href={`tel:+91${GtInfo[0]?.phoneIndia}`}
                    style={{ textDecoration: "none", color: "#fff " }}
                  >
                    +91 {GtInfo[0]?.phoneIndia || "Loading..."}
                  </a>
                </div>
              </button>
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Navbar;
