import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import "./Footer.css";
import Gtlogo from "../../../assets/newLogo.jpeg";


import ThemeContext from "../../../context/ThemeContext";

import { IoLocationOutline } from "react-icons/io5";
import { MdOutlineEmail } from "react-icons/md";
import { FiPhone } from "react-icons/fi";
import { FaWhatsapp } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

const Footer = ({ info }) => {
  const [activeSection, setActiveSection] = useState("#");
  const navigate = useNavigate();
  const location = useLocation();

  const { GtInfo } = useContext(ThemeContext);

  useEffect(() => {
    if (location.hash) {
      setActiveSection(location.hash);
    } else {
      setActiveSection("/");
      handleLinkClick();
    }
  }, [location.hash]);

  const isActive = (path) => (activeSection === path ? "active" : "");

  const handleLinkClick = () => {
    window.scrollTo({ top: "0 !important ", behavior: "smooth" });
  };

  return (
    <div className="footerMainContainer">
      {/* Footer Start */}
      <div className="footer">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-4 col-lg-5">
              <div className="footer-about">
                {/* <h3>About Us</h3> */}
                <img
                  src={Gtlogo}
                  alt="gtlogo"
                  width="100px"
                  height="100px"
                  style={{ borderRadius: "50%", marginBottom: "10px" }}
                />
                <p>
                  Welcome to{" "}
                  <span className="text-theme-color">Global Thamizhan</span> ,
                  Property management services for Non-Resident Indians (NRIs)
                  in Tamilnadu, offering a range of services to help NRIs to
                  manage and maintain their properties without having to be
                  physically present.
                </p>
              </div>
            </div>
            <div className="col-md-4 col-lg-3">
              <div className="footer-link footer-url-link">
                <div>
                  <h3>Company</h3>

                  <Link to="/" className={`${isActive("/")}`}>
                    Home
                  </Link>
                  <Link to="#about-us" className={`${isActive("#about-us")}`}>
                    About
                  </Link>
                  <Link to="#service" className={`${isActive("#service")}`}>
                    Service
                  </Link>
                  <Link
                    to="#booking-form"
                    className={`${isActive("#booking-form")}`}
                  >
                    Book Now
                  </Link>
                </div>
              </div>
            </div>

            <div className="col-sm-12 col-md-4 col-lg-4">
              <div className="footer-link footer-url-link">
                <div>
                  <h3>Address</h3>
                  <p className="my-2">
                    {GtInfo?.[0]?.email ? (
                      <a href={`mailto:${GtInfo[0].email}`}>
                        <MdOutlineEmail className="me-2" /> {GtInfo[0].email}
                      </a>
                    ) : (
                      "No email available"
                    )}
                  </p>
                  <p className="my-2">
                    {GtInfo?.[0]?.phoneIndia ? (
                      <a href={`tel:+91${GtInfo[0]?.phoneIndia}`}>
                        <FiPhone className="me-2" /> {GtInfo[0].phoneIndia}
                      </a>
                    ) : (
                      "No Phone available"
                    )}
                  </p>
                  <p className="my-2">
                    {GtInfo?.[0]?.whatsapp ? (
                      <a
                        href={`https://wa.me/91${GtInfo[0]?.whatsapp}`}
                        target="_blank"
                      >
                        <FaWhatsapp className="me-2" /> {GtInfo[0].whatsapp}
                      </a>
                    ) : (
                      "No whatsapp available"
                    )}
                  </p>
                  <p>
                    <p className="my-2">
                      {GtInfo?.[0]?.address ? (
                        <a href="#">
                          <IoLocationOutline className="me-2" />{" "}
                          {GtInfo[0].address}
                        </a>
                      ) : (
                        "No Address available"
                      )}
                    </p>
                  </p>
                  <div className="footer-social mt-3 ms-2">
                    <Link to="/#home">
                      {/* <i className="fab fa-twitter" /> */}
                      <FaXTwitter />
                    </Link>
                    <Link
                      to="https://www.facebook.com/unitedairtravelsramnad"
                      target="_blank"
                    >
                      <FaXTwitter />
                    </Link>
                    <Link to="/#home">
                      <FaXTwitter />
                    </Link>
                    <Link to="/#home">
                      <FaXTwitter />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="copyright">
          <div className="container-fluid">
            <div className="d-flex justify-content-between flex-wrap ">
              <div className="mt-2">
                <div className="copy-text">
                  <p className="text-light">
                    ©{" "}
                    <span className="text-theme-color ">Global Thamizhan</span>.
                    All Rights Reserved.
                  </p>
                </div>
              </div>
              <div className="mt-2">
                <p className="text-light" style={{ color: "#fff" }}>
                  Designed by
                  <Link
                    to="https://hellowtec.com/"
                    className="ms-1 text-danger"
                    target="_blank"
                  >
                    HELLO TECHNOLOGIES
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Footer End */}
    </div>
  );
};

export default Footer;
