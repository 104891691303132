import React, { useState } from "react";
import { ErrorMessage } from "formik";

const ServicesDropdown = ({ services, values, setFieldValue }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);

  const handleCheckboxChange = (e) => {
    const isChecked = e.target.checked;
    const value = e.target.value;

    const currentServices = values.services || [];
    const currentServiceInputs = values.serviceInputs || {};

    const selectedService = services.find(
      (service) => service.serviceName === value
    );

    if (selectedService) {
      const price = selectedService.price;

      if (isChecked) {
        setFieldValue("services", [...currentServices, value]);
        setFieldValue("serviceInputs", {
          ...currentServiceInputs,
          [value]: { price: price },
        });
      } else {
        setFieldValue(
          "services",
          currentServices.filter((v) => v !== value)
        );
        const updatedServiceInputs = { ...currentServiceInputs };
        delete updatedServiceInputs[value];
        setFieldValue("serviceInputs", updatedServiceInputs);
      }
    }
  };

  const getButtonText = () => {
    if (values.services && values.services.length > 0) {
      return values.services.join(", ");
    }
    return "Select Services";
  };

  const handleCloseDropdown = () => {
    setIsDropdownOpen(false);
  };

  return (
    <div className="form-group w-100">
      <label htmlFor="services">Services</label>
      <div className="dropdown">
        <button
          type="button"
          className="btn dropdown-toggle w-100 text-start text-white border"
          onClick={toggleDropdown}
          style={{
            overflowY: "scroll",
            scrollbarWidth: "none",
            padding: "8px",
          }}
        >
          {getButtonText()}
        </button>
        {isDropdownOpen && (
          <div className="dropdown-menu show w-100" style={{ zIndex: 99 }}>
            {services.length > 0 ? (
              services.map((service) => (
                <div
                  className="dropdown-item"
                  key={service.serviceName}
                  style={{
                    overflowX: "auto",
                    overflowY: "hidden",
                    whiteSpace: "nowrap",
                  }}
                >
                  <label className="form-check-label text-dark">
                    <input
                      type="checkbox"
                      value={service.serviceName}
                      className="form-check-input me-2 text-dark"
                      checked={values.services.includes(service.serviceName)}
                      onChange={handleCheckboxChange}
                    />
                    <p>
                      {service.serviceName} - ₹&nbsp;
                      {service.price}
                    </p>
                  </label>
                </div>
              ))
            ) : (
              <div className="dropdown-item">Loading services...</div>
            )}
            <button
              type="button"
              className="btn btn-sm btn-light text-dark w-100"
              onClick={handleCloseDropdown}
            >
              Close
            </button>
          </div>
        )}
      </div>
      <ErrorMessage name="services" component="div" className="text-danger" />
    </div>
  );
};

export default ServicesDropdown;

